import { defineComponent } from 'vue';
import Root from '../components/Root.vue';
import { onboardingStore } from '@/state/onboardingStore';
import { OnboardingMetricsTask } from '@/state/tasks';
export default defineComponent({
  components: {
    Root: Root
  },
  mounted: function mounted() {
    onboardingStore.commit.initializeAppTask(OnboardingMetricsTask);
  }
});