var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "onboarding-outer-wrap"
  }, [_c('div', {
    staticClass: "onboarding-content"
  }, [_c('Root')], 1), _vm._m(0)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('footer', {
    staticClass: "help"
  }, [_vm._v("Probleme? Hier melden: "), _c('a', {
    attrs: {
      "href": "tel:024189437924"
    }
  }, [_vm._v("0241-89437924")]), _vm._v(".")]);
}];
export { render, staticRenderFns };